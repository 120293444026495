const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6916600', space_js: '//xsfpb.moonreader.cn/production/gbaj/resource/k/common/aah/h.js' }];
const DIALOG = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6916601', space_js: '//xsfpb.moonreader.cn/production/h/cbk/static/lb_b/il.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6916602', space_js: '//xsfpb.moonreader.cn/production/idc_l/mcc_j/common/s.js' }];
const NATIVE = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6916603', space_js: '//xsfpb.moonreader.cn/source/jed-m-n/openjs/d/source/dkw.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_dialog: DIALOG,
    Home_banner: BANNER,
    Topic_native: NATIVE,
    Discover_native: NATIVE,
    Hot_native: NATIVE,
    Rank_native: NATIVE,
    TopicDetail_inter: INTER,
    TopicDetail_dialog: DIALOG,
    TopicDetail_banner: BANNER,
    TopicResult_inter: INTER,
    TopicResult_dialog: DIALOG,
    TopicResult_native: NATIVE,
    TopicResult_banner: BANNER,
};
