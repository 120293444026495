import { ref } from 'vue';

/** 首页插屏是否展示过 */
const HOME_INTER_SHOWN = ref<boolean>(false);

/** 互动页进入次数 */
const TOPIC_DETAIL_ENTER = ref<number>(-1);

/** 结果页进入次数 */
const TOPIC_RESULT_ENTER = ref<number>(0);

/** 广告总请求数 */
const TOTAL_NUMBER = ref<number>(0);

export function useAdControl() {
    return { HOME_INTER_SHOWN, TOPIC_DETAIL_ENTER, TOPIC_RESULT_ENTER, TOTAL_NUMBER };
}
